<template>
  <div class="publicParamsPage">
    <Listcontainer
      :searchText.sync="searchText"
      :placeholder="`参数名称/参数代码`"
      :uploadFile="uploadFilebtn"
      @exportFile="exportFile"
      @downTemplate="downTemplate"
      :multipleSelection="multipleSelection"
      @multipleDel="multipleDel"
      @uploadDone="isupload = true"
      :pagination="pagination"
      @add="add"
      @fetch="loadData"
      :isAdd="true"
      :addText="`添加参数`"
    >
      <template v-slot:grid>
        <Grid :Gridtype="1" :GridData="data" :isAdd="true" :isExport="true" :isUpdate="true" :isDel="true" @add="add" @deleteRow="deleteRow" @editRow="edit" />
      </template>
      <template v-slot:defalut> defalut-slot </template>
      <template v-slot:table>
        <Basetable :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" />
      </template>
    </Listcontainer>
    <el-dialog title="导入参数文件" :visible.sync="isupload" width="405px" :close-on-click-modal="false" append-to-body>
      <el-upload
        drag
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        action=""
        :file-list="fileList"
        :on-change="changeFile"
        :auto-upload="false"
        :disabled="fileList.length === 0 ? false : true"
        :multiple="false"
        :limit="1"
      >
        <div style="font-weight: bolder; font-size: 16px; margin-bottom: 20px; margin-top: 40px">拖拽文件到本区域上传</div>
        <div class="el-upload__text" style="margin-bottom: 20px">或者<em>点击这里</em>上传</div>
        <div class="el-upload__tip">请使用规定的参数模板</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.stop="uploadFile" size="mini" :disabled="fileList.length === 0 ? true : false">上传</el-button>
        <el-button @click="cloceUploadFile" size="mini">取 消</el-button>
      </span>
    </el-dialog>
    <!-- <Upload /> -->
    <Drawer
      :drawer.sync="drawer"
      @closeDrawer="closeDrawer"
      :title="'引用列表'"
      :AlertTitle="'该参数被以下设备模板和嵌套组引用'"
      :TemplateData="TemplateData.length ? TemplateData : null"
      :NestGroupData="NestGroupData.length ? NestGroupData : null"
      :isdel="true"
      @clickbtn="confirmDel()"
    />
  </div>
</template>
<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
import { TABLE } from '/app.config';
import Drawer from '@/components/drawer/index.vue';
const { edit, del } = TABLE.icons;
export default {
  components: {
    Basetable,
    Listcontainer,
    Grid,
    Drawer
  },
  data() {
    return {
      searchText: '',
      data: [],
      multipleSelection: [],
      isupload: false,
      uploadFilebtn: ['import', 'export', 'download'],
      fileList: [],
      groupId: '',
      drawer: false,
      TemplateData: [],
      NestGroupData: [],
      columns: [
        {
          prop: 'name',
          label: '参数名称'
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'valueType',
          label: '数据类型'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'status',
          label: '报警属性'
        }
      ],
      pagination: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      },
      operations: {
        width: 100,
        handles: [
          {
            icon: edit,
            name: '编辑',
            handle: this.edit
          },
          {
            icon: del,
            name: '删除',
            handle: this.deleteRow
          }
        ]
      },
      isdels: false,
      paramIds: [],
      paramId: ''
    };
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  mounted() {
    let item;
    if (localStorage.getItem('this_basicGroup')) {
      item = JSON.parse(localStorage.getItem('this_basicGroup'));
      if (item === this.$route.query.data) {
        this.loadData(item);
      } else {
        if (this.$route.query.data !== '[object Object]') {
          item = this.$route.query.data;
          localStorage.setItem('this_basicGroup', JSON.stringify(item));
          this.loadData(item);
        } else {
          item = JSON.parse(localStorage.getItem('this_basicGroup'));
          this.loadData(item);
        }
      }
    } else {
      if (this.$route.query.data) {
        item = this.$route.query.data;
        localStorage.setItem('this_basicGroup', JSON.stringify(item));
        this.loadData(item);
      }
    }
  },
  methods: {
    add() {
      localStorage.setItem('isGroup', 'group');
      this.$router.push({ path: '/basicParams/editparams', query: { data: null } });
    },
    edit(item) {
      localStorage.setItem('isGroup', 'group');
      this.$router.push({ path: '/basicParams/editparams', query: { data: { ...item, isGroup: true } } });
    },
    async loadData(param = {}) {
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        groupId: JSON.parse(localStorage.getItem('this_basicGroup')).groupId
      };
      const res = await this.$apis.publicParams.list(data);
      this.data = res.items;
      this.data.forEach(item => {
        item.isAlarm === 0 ? (item.status = '否') : (item.status = '是');
      });
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
      this.Gridtype = 1;
    },
    downTemplate() {
      this.$apis.basicParamsGroup.downloadTemplate();
    },
    changeFile(file) {
      this.fileList.push(file);
    },
    cloceUploadFile() {
      this.fileList = [];
      this.isupload = false;
    },
    async uploadFile() {
      const item = JSON.parse(localStorage.getItem('this_basicGroup'));
      const groupId = item.groupId;
      const file = this.fileList[0].raw;
      const { fail } = await this.$apis.basicParamsGroup.importParam({ groupId, file });
      if (fail) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      this.fileList = [];
      this.isupload = false;

      this.loadData(item);
    },
    async multipleDel() {
      this.isdels = true;
      this.paramIds = [];
      this.multipleSelection.forEach(item => this.paramIds.push(item.paramId));
      const { deviceParamGroupVoList, deviceTemplateVoList } = await this.$apis.publicParams.previewCite({ paramIds: this.paramIds.join(','), type: 2 });
      if (deviceParamGroupVoList.length || deviceTemplateVoList.length) {
        this.NestGroupData = deviceParamGroupVoList;
        this.TemplateData = deviceTemplateVoList;
        this.drawer = true;
      } else {
        this.$confirm('确认要删除已选参数吗?', '删除参数', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(async () => {
          const { fail } = await this.$apis.publicParams.del({ paramIds: this.isdels ? this.paramIds.join(',') : this.paramId, type: 2 });
          this.loadData();
          if (fail) {
            return false;
          }
        });
      }
    },
    async confirmDel() {
      const { fail } = await this.$apis.publicParams.del({ paramIds: this.isdels ? this.paramIds.join(',') : this.paramId, type: 2 });
      this.drawer = false;
      this.loadData();
      if (fail) {
        return false;
      }
    },
    async deleteRow(row) {
      this.isdels = false;
      this.paramId = row.paramId;
      const { deviceParamGroupVoList, deviceTemplateVoList } = await this.$apis.publicParams.previewCite({ paramIds: row.paramId, type: 2 });
      if (deviceParamGroupVoList.length || deviceTemplateVoList.length) {
        this.NestGroupData = deviceParamGroupVoList;
        this.TemplateData = deviceTemplateVoList;
        this.drawer = true;
      } else {
        this.$confirm('确认要删除已选参数吗?', '删除参数', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(async () => {
          const { fail } = await this.$apis.publicParams.del({ paramIds: row.paramId, type: 1 });
          this.loadData();
          if (fail) {
            return false;
          }
        });
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
    exportFile() {
      if (this.data.length === 0) {
        this.$message.warning('无可导出的数据');
        return false;
      }
      const item = JSON.parse(localStorage.getItem('this_basicGroup'));
      const data = {
        groupId: item.groupId
      };
      this.$apis.basicParamsGroup.ExportParam(data);
    }
  }
};
</script>

<style>
tr th {
  background-color: #f3f3f3 !important;
}
.el-table thead {
  color: #585858 !important;
  font-size: 14px;
}
.el-icon-menu {
  margin-left: 20px;
  cursor: pointer;
}
.publicParamsPage {
  height: 100%;
}
</style>
